<template>
  <v-card>
    <v-img :src="require('@/assets/background.svg')" max-height="100%">
      <v-card-text class="text-center headline primary--text">
        Políticas de Privacidade Apetrus
        <div class="subtitle-1 grey--text text--darken-1">
          Desça a página para confirmar a leitura
        </div>
      </v-card-text>
      <v-card-text
        id="scroll-target"
        class="overflow-y-auto"
        style="height: 60vh"
        v-scroll:#scroll-target="onScroll"
      >
        <div
          v-html="politicasDePrivacidade.texto"
          v-if="politicasDePrivacidade"
          class="text-justify grey--text text--darken-3"
        ></div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="green"
          block
          text
          :disabled="!podeConfirmar"
          :loading="loading"
          @click="confirmarLeitura()"
        >
          CONFIRMAR LEITURA
        </v-btn>
      </v-card-actions>
    </v-img>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      podeConfirmar: false
    };
  },
  mounted() {
    this.$store.dispatch("carregarPoliticasDePrivacidade");
  },
  methods: {
    onScroll(e) {
      if (
        e.target.scrollTop + 50 >=
        e.target.scrollHeight - e.target.offsetHeight
      ) {
        this.podeConfirmar = true;
      }
    },
    confirmarLeitura() {
      this.$emit("confirmar");
      this.$emit("fechar");
    },
    reset() {
      this.podeConfirmar = false;
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    politicasDePrivacidade() {
      return this.$store.getters.politicasDePrivacidade;
    }
  }
};
</script>
